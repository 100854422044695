<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined color="#f1f1f1">
        <v-card-title>New Message</v-card-title>
        <v-card-text>
          <!-- Start New Service Request section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="font-weight-bold">Send To</h6>
                  <v-autocomplete
                    v-model="newMessage.sendTo"
                    :items="recipients"
                    item-text="fullName"
                    item-value="id"
                    dense
                    chips
                    small-chips
                    label="Send To"
                    multiple
                    solo
                    :error-messages="sendToErrors"
                    @input="$v.newMessage.sendTo.$touch()"
                    @blur="$v.newMessage.sendTo.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newMessage.subject"
                    label="Subject"
                    :error-messages="subjectErrors"
                    @input="$v.newMessage.subject.$touch()"
                    @blur="$v.newMessage.subject.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="newMessage.body"
                    label="Message"
                    :error-messages="bodyErrors"
                    @input="$v.newMessage.body.$touch()"
                    @blur="$v.newMessage.body.$touch()"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <div
                    v-for="attachment in attachmentsObj"
                    cols="12"
                    class="darggable-col"
                    :key="attachment.id"
                  >
                    <MediaUploader
                      class="pb-5"
                      uploadType="file"
                      :key="attachment.id"
                      :id="`multiFilePost_${attachment.id}`"
                      :index="attachment.id"
                      :accept="accept"
                      :multiple="true"
                      :extensions="extensions"
                      :sizeImage="10485760"
                      :sizeVideo="104857600"
                      :fileId.sync="attachment.fileId"
                      :file-url.sync="attachment.url"
                      :isUploading.sync="isFileUploading"
                      @onMultipleUploaded="onMultipleAddFile"
                      @onChange="onChange"
                      @onDeleteFile="onDeleteFile"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-radio-group v-model="newMessage.isActionRequired" row>
                    <template v-slot:label>
                      <div>Action Required:</div>
                    </template>
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-btn class="orange-bg white--text" @click="sendMessage">
                Send Message
              </v-btn>
            </v-container>
          </v-form>
          <!-- End New Service Request section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  API_MESSAGES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    communityInfo: {},
    newMessage: {
      sendTo: [],
      subject: "",
      body: "",
      attachments: [],
      isActionRequired: "0"
    },
    recipients: [],
    menu1: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return {
      newMessage: {
        subject: {
          required
        },
        body: {
          required
        },
        sendTo: {
          required
        }
      }
    };
  },
  watch: {},
  async mounted() {},
  async created() {
    await this.getComunityInfo();
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);
            self.createBreadcrumb();
            self.setInternalBreadcrumbs();

            self.recipients.push({
              id: self.communityInfo.propertyManager.id,
              fullName: self.communityInfo.propertyManager.fullName
            });
            self.newMessage.sendTo.push(self.communityInfo.propertyManager.id);
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
        });
    },
    sendMessage() {
      this.newMessage.isNew = true;
      this.newMessage.isActionRequired =
        this.newMessage.isActionRequired == "1";

      this.newMessage.recipients = this.newMessage.sendTo.map(el => {
        return { id: el };
      });

      this.newMessage.attachments = this.attachmentsObj
        .filter(i => i.url && i.url.length > 0)
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.newMessage.attachments = this.newMessage.attachments.filter(
        (item, index) => this.newMessage.attachments.indexOf(item) === index
      ); //remove duplicates

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_MESSAGES}`,
          data: self.newMessage
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Message has been sent";

            self.$router.push({
              name: "CustomerMessages",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Messages",
          route: "/customers/" + this.customerId + "/messages"
        },
        { title: "New Message" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Messages",
          route: "/customers/" + this.customerId + "/messages"
        },
        { title: "New Message" }
      ]);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    sendToErrors() {
      const errors = [];
      if (!this.$v.newMessage.sendTo.$dirty) return errors;
      !this.$v.newMessage.sendTo.required && errors.push("Required.");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.newMessage.subject.$dirty) return errors;
      !this.$v.newMessage.subject.required && errors.push("Required.");
      return errors;
    },
    bodyErrors() {
      const errors = [];
      if (!this.$v.newMessage.body.$dirty) return errors;
      !this.$v.newMessage.body.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
